.TextField {
  > input {
    font-size: 16px;
    padding: 20px 16px;
    border-radius: 10px;
    outline: none;

    &,
    &:hover:enabled,
    &:active:enabled {
      transition: border-color 0.2s ease-in-out;
    }
  }

  &--dark {
    label {
      color: #fcfaf0;
    }

    > input {
      width: 100%;
      color: #fcfaf0;
      background: #3e2d55;
      border: 1px solid rgba(252, 244, 240, 0.1);

      &:hover:enabled {
        border-color: rgba(252, 244, 240, 0.5);
      }

      &:active:enabled {
        border-color: #fcfaf0;
      }
    }
  }

  &--light {
    label {
      color: var(--primary);
    }

    > input {
      background: rgb(242, 239, 237);
      color: var(--primary);
      border: 2px solid rgb(242, 239, 237);

      &:active:enabled,
      &:focus,
      &:hover:enabled {
        border-color: rgb(237, 225, 217);
      }
    }
  }
}
