.UserGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    margin: 0 0 16px;
  }

  &__pictures {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    &--left {
      justify-content: flex-start;
    }

    &--right {
      justify-content: flex-end;
    }

    &--center {
      justify-content: center;
    }
  }

  &__picture {
    box-shadow: 0 0 3px #ddd;

    &:first-child {
      margin-left: 0;
    }
  }
}
