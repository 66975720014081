.NewPassword {
  width: 100%;
  max-width: 620px;
  margin: 0 0 50px;

  &__page-content {
    justify-content: start;
    padding-top: 10vw;
  }

  h1 {
    margin: 20px 0;
  }

  &__secondary-link {
    color: var(--secondary);
    font-size: 16px;
    text-decoration: none;
  }

  &__description {
    color: var(--secondary);
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 56px;
  }

  &__sign-in {
    text-align: center;
    margin-top: 75px;
  }
}
