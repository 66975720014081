.ResetPassword {
  width: 40vw;

  h1 {
    margin: 16px 0 30px;
  }

  &__description {
    color: var(--secondary);
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 56px;
  }
}
