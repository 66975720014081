.SettingsUsersTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;

  > thead,
  > tbody {
    > tr {
      letter-spacing: 0.35px;
      font-size: 14px;
      background-color: #4a3960;

      > th,
      > td {
        min-width: 150px;
        color: #fcfaf0;
        padding: 10px;
        font-style: normal;

        &:first-child {
          min-width: 225px;
        }

        &:nth-child(3) {
          width: 100px;
          min-width: 100px;
          text-align: right;
        }

        &:last-child {
          min-width: 50px;
          text-align: right;
          padding-right: 50px;
        }
      }
    }
  }

  > thead > tr > th {
    font-weight: bold;
    font-size: 9px;
    text-transform: uppercase;
    text-align: left;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: var(--primary);
  }
}
