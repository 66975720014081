.Dashboard {
  width: 60vw;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > h2 {
      margin-top: 0;
    }
  }

  &__loader {
    height: 50vh;
    display: flex;
    padding-top: 10vh;
    align-items: flex-start;
    justify-content: center;
  }

  &__table {
    height: 60vh;
    overflow-y: scroll;
  }

  @media (orientation: portrait) {
    @media (max-device-width: 600px) {
      width: 100%;

      &__header {
        align-items: start;
        flex-direction: column;
      }
    }
  }
}
