.EmailVerification {
  width: 40vw;

  h1 {
    margin: 16px 0 30px;
  }

  &__description {
    font-size: 20px;
    display: block;
    margin: 0 0 50px;
  }

  &__resend-email {
    display: block;

    color: var(--secondary);
    font-size: 12px;

    a {
      color: var(--light-yellow);
    }
  }

  &__page-content {
    justify-content: start;
    padding-top: 10vw;
  }
}
