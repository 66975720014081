.RollingTomato {
  width: 33px;
  height: 30px;
  border-radius: 25px;
  background: #f07068;
  animation: RollingTomato__spin 0.5s linear infinite;
}

@keyframes RollingTomato__spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
