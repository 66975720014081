.AcceptInvite {
  width: 100%;
  max-width: 620px;
  margin: 0 0 50px;
}

.AcceptInvite__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span,
  a {
    color: var(--secondary);
    font-size: 12px;
  }

  a {
    text-decoration: underline;
  }
}
