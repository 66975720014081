.Countdown {
  &__progress {
    border-radius: 40px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &--work {
      background: linear-gradient(
        90deg,
        var(--dark-red) 0%,
        var(--dark-red) var(--percentage),
        var(--light-red) var(--percentage)
      );
    }

    &--break {
      background: linear-gradient(
        90deg,
        var(--dark-green) 0%,
        var(--dark-green) var(--percentage),
        var(--light-green) var(--percentage)
      );
    }
  }

  &__duration {
    position: relative;
  }
}
