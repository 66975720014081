.SearchField {
  &__button {
    border-radius: 10px;
    width: 56px;
    height: 56px;

    &.dark {
      background: #3e2d55;
      border: 1px solid rgba(252, 244, 240, 0.1);

      &:hover {
        border-color: rgba(252, 244, 240, 0.5);
      }

      path,
      circle,
      line {
        stroke: var(--light-yellow);
      }
    }

    &.light {
      background: rgba(242, 215, 202, 0.3);
      border: 2px solid rgba(242, 215, 202, 0.1);

      &:focus,
      &:hover {
        border-color: rgba(242, 215, 202, 0.5);
      }

      path,
      circle,
      line {
        stroke: var(--primary);
      }
    }
  }

  > .TextField {
    input {
      height: 56px;
    }

    &--light {
      input {
        background: rgba(242, 215, 202, 0.3);
        color: var(--primary);
        border: 2px solid rgba(242, 215, 202, 0.1);

        &:focus,
        &:hover {
          border-color: rgba(242, 215, 202, 0.5);
        }
      }
    }
  }
}
