.TomatoRoll {
  background: var(--primary);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fafcf0;

  h1 {
    margin: -10px 0 0 15px;
  }
}
