.SpaceNotFoundCard {
  background-color: #f2efed;
  border-radius: 40px;
  height: 304px;
  width: 288px;
  position: relative;

  &__background {
    opacity: 0.75;
    position: absolute;
    border-radius: 40px;
    height: 100%;
    background-size: cover;
    background-position: center;
    width: 100%;
    display: flex;
    font-weight: bold;
    font-size: 44px;
    align-items: center;
    justify-content: center;
  }
}
