.SpaceCard {
  background: rgba(var(--secondary-rgb), 0.25);
  border-radius: 40px;
  height: 304px;
  width: 288px;

  &__wrapper {
    padding: 20px 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    justify-content: space-between;
  }

  &,
  &:hover {
    transition: all 0.1s ease-in-out;
  }

  &--work {
    background-color: var(--light-red);
  }

  &--break {
    background-color: var(--light-green);
  }

  &__title {
    color: var(--primary);
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    position: relative;
    z-index: 1;
    word-wrap: break-word;
  }

  &__countdown {
    font-family: Inconsolata;
    color: var(--primary);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.02em;
  }

  &__users {
    position: relative;
    z-index: 1;
    margin-top: 20px;
  }

  a {
    position: relative;
    z-index: 1;
    background: rgba(var(--secondary-rgb), 0.75);
    border-radius: 100px;
    color: var(--primary);
    padding: 8px 16px;
    text-decoration: none;
    width: fit-content;

    &:hover {
      background: var(--secondary);
    }
  }

  @media (orientation: landscape) {
    @media (max-device-height: 600px) {
      height: 200px;
    }
  }

  @media (max-width: 550px) and (max-height: 350px) {
    height: 200px;
  }
}
