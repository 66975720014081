.Button {
  display: inline-block;
  border: 0;
  padding: 16px 40px;
  background: #dcbcfd;
  border-radius: 100px;
  font-size: 16px;
  font-family: Hansen Grotesque;
  color: var(--primary);
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: darken(#dcbcfd, 5%);
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    opacity: 0.6;
  }

  &.primary.outline {
    background: var(--primary);
    border: solid 1px var(--secondary);
    color: var(--secondary);

    &:hover {
      background: var(--secondary);
      color: var(--primary);
    }

    &,
    &:hover {
      transition: all 0.2s ease-in-out;
    }
  }

  &.outline {
    background: transparent;
    border: solid 1px var(--secondary);
    color: var(--secondary);
    border-radius: 100px;

    &:hover {
      background: var(--secondary);
      color: var(--primary);
    }

    &,
    &:hover {
      transition: all 0.2s ease-in-out;
    }
  }

  &,
  &:hover {
    transition: all 0.2s ease-in-out;
  }
}
