.Notification {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  color: var(--primary);
  display: flex;
  flex-direction: row;
  font-size: 12px;
  padding: 16px;
  position: fixed;
  bottom: 24px;
  z-index: 100;
  max-width: 650px;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;

  &__text {
    flex-grow: 1;
    margin-right: 24px;
  }

  &__button {
    font-weight: bold;
    text-decoration-line: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
}
