.SettingsUsersTableRow {
  &__name {
    display: flex;
    align-items: center;
  }

  &__picture {
    margin-right: 10px !important;
    margin-left: 0 !important;
  }

  > td {
    transition: 0.2s padding ease-in-out;
  }

  &__toggle-admin {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    transition: 0.25s all ease-in-out !important;

    .Checkbox__label-text {
      margin: 0 0 18px;
    }
  }

  &__remove-user {
    padding: 5px 14px !important;
    font-size: 10px !important;
  }
}
