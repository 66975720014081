.SettingsMenu {
  margin-right: 50px;
  width: 100%;
  max-width: 200px;

  &__link {
    display: block;
    text-decoration: none;
    color: var(--light-yellow);
    opacity: 0.5;
    padding: 12.5px 0;

    > svg {
      float: right;
      opacity: 0;
    }

    &--selected,
    &--selected:hover {
      opacity: 1;

      > svg {
        opacity: 1;
      }
    }

    &:hover {
      opacity: 1;

      > svg {
        opacity: 0.5;
      }
    }

    &:last-child {
      margin-top: 20px;
    }
  }

  @media (max-device-width: 768px) {
    display: flex;
    max-width: inherit;
    margin-right: 0;

    &__link {
      padding: 5px 5px 20px;

      > svg {
        display: none;
      }

      &:last-child {
        margin-top: 0;
        margin-left: auto;
      }
    }
  }
}
