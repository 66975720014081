.Slider {
  &__input {
    display: block;
    width: 100%;
    height: 15px;
    margin: 8px 0 16px;
    border: 1px solid;
    border-color: rgba(252, 244, 240, 0.1);
    border-radius: 10px;
    outline: none;
    background: #3e2d55;
    appearance: none;
    cursor: pointer;

    &:not([disabled]),
    &:hover:not([disabled]) {
      transition: border-color 0.2s ease-in-out;
    }

    &:hover:not([disabled]) {
      border-color: rgba(252, 244, 240, 0.5);
    }

    &:disabled {
      cursor: default;

      &::-webkit-slider-thumb {
        cursor: default;
        background: #36244d;
        box-shadow: 0 0 2px rgba(255, 255, 255, 0.45);
      }

      &::-moz-range-thumb {
        cursor: default;
        background: #36244d;
        box-shadow: 0 0 2px rgba(255, 255, 255, 0.45);
      }
    }

    &::-webkit-slider-thumb {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      cursor: grab;
      appearance: none;
      background: darken(#dcbcfd, 10%);
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.45);
    }

    &::-moz-range-thumb {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      cursor: grab;
      background: darken(#dcbcfd, 10%);
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.45);
      border: 0;
    }
  }
}
