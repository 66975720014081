.TimerOption {
  --minutes-font-size: 80px;
  --label-font-size: 20px;
  --option-padding-vertical: 60px;
  --option-padding-horizontal: 40px;
  --option-border-radius: 40px;

  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: var(--option-padding-vertical);
  padding-bottom: var(--option-padding-vertical);
  padding-left: var(--option-padding-horizontal);
  padding-right: var(--option-padding-horizontal);
  margin: 10px;
  border-radius: var(--option-border-radius);
  cursor: pointer;

  &,
  &:hover {
    transition: background 0.1s ease-in-out;
  }

  &__minutes {
    font-size: var(--minutes-font-size);
    font-weight: bold;
  }

  &__label {
    font-size: var(--label-font-size);
  }

  &--break {
    border: 1px solid var(--dark-green);
    background-color: var(--light-green);

    &:hover {
      background-color: var(--dark-green);
    }
  }

  &--work {
    border: 1px solid var(--dark-red);
    background-color: var(--light-red);

    &:hover {
      background-color: var(--dark-red);
    }
  }

  @media (max-device-width: 1200px) {
    padding-top: calc(var(--option-padding-vertical) * 0.8);
    padding-bottom: calc(var(--option-padding-vertical) * 0.8);
    padding-left: calc(var(--option-padding-horizontal) * 0.8);
    padding-right: calc(var(--option-padding-horizontal) * 0.8);
    border-radius: calc(var(--option-border-radius) * 0.8);

    &__minutes {
      font-size: calc(var(--minutes-font-size) * 0.8);
    }

    &__label {
      font-size: calc(var(--label-font-size) * 0.8);
    }
  }

  @media (max-device-width: 1000px) {
    padding-top: calc(var(--option-padding-vertical) * 0.6);
    padding-bottom: calc(var(--option-padding-vertical) * 0.6);
    padding-left: calc(var(--option-padding-horizontal) * 0.6);
    padding-right: calc(var(--option-padding-horizontal) * 0.6);
    border-radius: calc(var(--option-border-radius) * 0.6);

    &__minutes {
      font-size: calc(var(--minutes-font-size) * 0.6);
    }

    &__label {
      font-size: calc(var(--label-font-size) * 0.6);
    }
  }

  @media (max-device-width: 800px) {
    padding-top: calc(var(--option-padding-vertical) * 0.4);
    padding-bottom: calc(var(--option-padding-vertical) * 0.4);
    padding-left: calc(var(--option-padding-horizontal) * 0.4);
    padding-right: calc(var(--option-padding-horizontal) * 0.4);
    border-radius: calc(var(--option-border-radius) * 0.4);

    &__minutes {
      font-size: calc(var(--minutes-font-size) * 0.4);
    }

    &__label {
      font-size: calc(var(--label-font-size) * 0.4);
    }
  }

  @media (max-device-width: 450px) and (max-device-height: 700px) {
    padding-top: calc(var(--option-padding-horizontal) * 0.4);
    padding-bottom: calc(var(--option-padding-horizontal) * 0.4);
    padding-left: calc(var(--option-padding-horizontal) * 0.4);
    padding-right: calc(var(--option-padding-horizontal) * 0.4);
    border-radius: calc(var(--option-border-radius) * 0.4);
    margin: 5px;
  }
}
