.Heading {
  font-weight: bold;
  font-size: 40px;
  margin: 16px 0 56px;

  @media (max-device-width: 600px) {
    font-size: 35px;
  }
  @media (max-device-height: 600px) {
    font-size: 35px;
  }
}
