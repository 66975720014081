.DashboardTable {
  border-collapse: separate;
  border-spacing: 0 8px;
  width: 100%;

  > thead,
  > tbody {
    width: 100%;

    > tr {
      > th,
      > td {
        min-width: 150px;
        color: #fcfaf0;
        padding: 10px;
        font-style: normal;

        &:first-child {
          min-width: 225px;
        }

        &:last-child {
          min-width: 50px;
          text-align: right;
          padding-right: 50px;
        }
      }
    }
  }

  > thead > tr > th {
    font-weight: bold;
    font-size: 9px;
    text-transform: uppercase;
    text-align: left;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: var(--primary);
  }
}
