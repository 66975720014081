.SpaceLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    margin: 0 0 16px;
  }

  &__links {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    &--left {
      justify-content: flex-start;
    }

    &--right {
      justify-content: flex-end;
    }

    &--center {
      justify-content: center;
    }
  }

  &__link {
    width: 40px;
    height: 40px;

    &:first-child {
      margin-left: 0;
    }
  }
}
