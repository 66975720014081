.SpaceLoadingCard {
  background-color: #f2efed;
  border-radius: 40px;
  height: 200px;
  margin: 0 10px;
  padding: 50px 30px;
  width: 200px;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
}
