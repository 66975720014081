.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  border-radius: 6px;
  padding: 5px 10px;
  overflow: hidden;
  white-space: nowrap;

  /* Tooltip colors*/
  background-color: rgba(var(--secondary-rgb), 0.75);
  color: var(--primary);

  /* Position the tooltip */
  position: absolute;
  right: 110%;
  top: 20px;

  /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
  opacity: 0;
  transition: opacity 1s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
