.SpaceCreateCard {
  background: rgba(var(--secondary-rgb), 0.1);
  border: 1px dashed var(--secondary);
  border-radius: 40px;
  height: 304px;
  width: 288px;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;

  &__title {
    color: var(--primary);
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    text-align: center;
  }

  a {
    background: rgba(var(--secondary-rgb), 0.75);
    border-radius: 15px;
    color: var(--primary);
    display: block;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    padding: 12px 16px;
    text-decoration: none;

    &:hover {
      background: var(--secondary);
    }
  }

  @media (orientation: landscape) {
    @media (max-device-height: 600px) {
      height: 200px;
    }
  }

  @media (max-width: 550px) and (max-height: 350px) {
    height: 200px;
  }
}
