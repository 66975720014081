.Sidebar {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0 20px;
  position: fixed;
  right: 0;

  &__icons {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
  }

  &--secondary {
    background: transparent;

    a {
      path,
      circle,
      rect {
        stroke: var(--primary);
      }

      &:hover {
        background: var(--light-red);
      }
    }
  }

  &--primary {
    background: var(--primary);

    a {
      path,
      circle,
      rect {
        stroke: var(--light-yellow);
      }

      &:hover {
        background: var(--secondary);

        path,
        circle,
        rect {
          stroke: var(--primary);
        }
      }
    }
  }

  &__icon {
    margin: 10px 0;

    a {
      display: block;
      padding: 20px;
      border-radius: 20px;
      cursor: pointer;

      opacity: 0.3;

      path,
      circle,
      rect {
        stroke-width: 1px;
      }

      &:hover {
        opacity: 1;
      }

      &,
      &:hover {
        transition: 0.2s ease-in-out;
      }

      &.selected {
        opacity: 1;

        path,
        circle,
        rect {
          stroke-width: 2px;
        }
      }
    }

    &--leave {
      a {
        background: #fde2d8;
        opacity: 1;

        &:hover {
          background: #f4ddd2;
        }

        path {
          stroke: #fd9290;
        }
      }
    }
  }

  @media (orientation: portrait) {
    @media (max-device-width: 600px) {
      left: 0;
      bottom: 0;
      height: fit-content;
      z-index: 1;

      &__icons {
        flex-direction: row;
      }
    }
  }
  @media (max-width: 550px) and (max-height: 350px) {
    display: none;
  }
}
