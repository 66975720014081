.SettingsUsers {
  width: 100%;
  max-width: 992px;
  margin: 0 0 50px;
  display: flex;
  flex-direction: column;

  &__content {
    display: flex;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__table {
    flex: 1;
    overflow: scroll;
  }

  @media (max-device-width: 768px) {
    h1 {
      margin: 0 0 20px;
    }

    &__content {
      flex-direction: column;
    }
  }
}
