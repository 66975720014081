.NewOrganisation {
  width: 100%;
  max-width: 620px;
  margin: 0 0 50px;

  h1 {
    margin: 16px 0 30px;
  }

  .NewOrganisation__description {
    font-size: 20px;
    display: block;
    margin: 0 0 50px;
  }

  .NewOrganisation__actions {
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
