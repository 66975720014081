.SettingsProfile {
  width: 100%;
  max-width: 992px;
  margin: 0 0 50px;
  display: flex;
  flex-direction: column;

  &__content {
    display: flex;
  }

  &__form {
    flex: 1;
  }

  &__location_warning {
    font-size: 9px;
    text-transform: uppercase;
  }

  @media (max-device-width: 768px) {
    h1 {
      margin: 0 0 20px;
    }

    &__content {
      flex-direction: column;
    }
  }
}
