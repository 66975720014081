.TimerPicker {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;

  h1 {
    position: absolute;
    top: -3em;
    width: 100%;
    text-align: center;
  }

  &__list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
}
