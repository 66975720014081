.Checkbox {
  &__switch {
    position: relative;
    display: inline-block;
  }

  &__switch-input {
    display: none;
  }

  &__switch-label {
    display: block;
    width: 46px;
    height: 15px;
    text-indent: -150%;
    clip: rect(0 0 0 0);
    color: transparent;
    user-select: none;

    &::before,
    &:hover::before {
      transition: border-color 0.25s ease-in-out;
    }

    &:hover::before {
      border-color: rgba(252, 244, 240, 0.5);
    }

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      cursor: pointer;
    }

    &::before {
      width: 100%;
      height: 100%;
      background-color: #3e2d55;
      border-radius: 9999em;
      border: 1px solid;
      border-color: rgba(252, 244, 240, 0.1);
    }

    &::after {
      top: -5px;
      left: 0;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: #36244d;
      box-shadow: 0 0 2px rgba(255, 255, 255, 0.45);
      transition: left 0.25s ease;
    }
  }

  &__switch-input:checked + &__switch-label::after {
    left: 24px;
    background: darken(#dcbcfd, 10%);
  }
}
