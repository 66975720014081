.PageContent {
  width: 100%;
  min-height: 100%;
  padding: 0 5vw;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &:not(&--center) {
    padding-top: 10vh;
    align-items: center;
    justify-content: flex-start;
  }

  &--center {
    justify-content: center;
    align-items: center;
  }

  &--dark {
    background: var(--primary);
    color: var(--light-yellow);
  }

  &--light {
    color: var(--primary);
  }
}
