.Footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1;

  > ul {
    text-align: center;
    list-style-type: none;
    padding: 1rem;
    margin: 0;

    > li {
      display: inline;
      text-align: center;
      padding: 0 0.85rem;

      > a {
        font-size: 0.85rem;
        text-decoration: none;
      }
    }
  }

  &--dark {
    background-color: rgba(252, 250, 240, 0.55);

    a {
      color: var(--primary);
    }
  }

  &--light {
    background-color: rgba(54, 36, 77, 0.55);

    a {
      color: var(--secondary);
    }
  }
}
