.Spaces {
  width: 60vw;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0 56px;

    h1 {
      margin: 0;
    }
  }

  &__scrollable {
    margin-bottom: 50px;
    width: 60vw;
    padding: 20px 0;

    /* Grid */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(288px, 288px));
    grid-gap: 16px;
    justify-content: center;
  }

  @media (orientation: landscape) {
    @media (max-device-height: 600px) {
      &__header {
        margin: 16px 0 0;
      }

      &__scrollable {
        overflow-y: scroll;
        margin-top: 10px;
      }
    }
  }

  @media (orientation: portrait) {
    @media (max-device-width: 600px) {
      width: 100%;

      &__header {
        align-items: start;
        flex-direction: column;
        margin: 16px 0 0;

        h1 {
          margin-bottom: 20px;
        }
      }

      &__scrollable {
        overflow-x: scroll;
        height: 60vh;
        width: 90vw;
        min-width: 260px;
        margin-top: 10px;
        grid-template-columns: none;
        grid-template-rows: auto;
        display: flex;
      }
    }
  }

  @media (max-width: 550px) and (max-height: 350px) {
    width: 100%;

    &__header {
      align-items: start;
      flex-direction: column;
      margin: 16px 0 0;

      h1 {
        margin-bottom: 20px;
      }
    }

    &__scrollable {
      overflow-x: scroll;
      height: 60vh;
      width: 90vw;
      min-width: 260px;
      margin-top: 10px;
      grid-template-columns: none;
      grid-template-rows: auto;
      display: flex;
    }
  }
}
