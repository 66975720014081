.Dropdown {
  &__select {
    font-size: 16px;
    width: 100%;
    min-width: 213px;
    padding: 20px 16px;
    background: #3e2d55;
    border: 1px solid rgba(252, 244, 240, 0.1);
    border-radius: 10px;
    color: #fcfaf0;
    outline: none;
    appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='%23FCFAF0' height='25' viewBox='0 0 25 25' width='25' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 17px;

    &:hover {
      cursor: pointer;
      border-color: rgba(252, 244, 240, 0.5);
    }

    &:active {
      border-color: #fcfaf0;
    }

    &,
    &:hover,
    &:active {
      transition: border-color 0.2s ease-in-out;
    }
  }
}
