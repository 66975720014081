.Timer {
  display: flex;
  align-items: center;
  flex-direction: column;

  &__headline {
    font-size: 20px;
    z-index: 1;
  }

  button {
    z-index: 1;
  }

  &__countdown {
    font-family: Inconsolata, monospace;
    font-size: 140px;
    font-weight: 700;
    margin: 0;
    padding: 0;

    @media (max-device-width: 600px) {
      font-size: 120px;
    }
  }

  &__cycle--break {
    border: 1px solid var(--dark-green) !important;
    background-color: var(--light-green) !important;
    color: var(--primary) !important;

    &:hover {
      background-color: var(--dark-green) !important;
    }
  }

  &__cycle--work {
    border: 1px solid var(--dark-red) !important;
    background-color: var(--light-red) !important;
    color: var(--primary) !important;

    &:hover {
      background-color: var(--dark-red) !important;
    }
  }
}
