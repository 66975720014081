.SignUp {
  width: 100%;
  max-width: 620px;
  margin: 0 0 50px;

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span,
    a {
      color: var(--secondary);
      font-size: 12px;
    }

    a {
      text-decoration: underline;
    }
  }

  &__sign-in {
    text-align: center;
    margin-top: 75px;
  }

  &__sign-in-link {
    color: var(--secondary);
    font-size: 16px;
    text-decoration: none;
  }
}
