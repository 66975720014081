.Space {
  color: var(--primary);

  &__page-content {
    &--break {
      background-color: var(--light-green);
    }

    &--work {
      background-color: var(--light-red);
    }
  }

  &__mode {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: relative;

    button {
      margin-top: 10px;
      margin-bottom: 0.5em;
    }
  }

  &__cycle--break {
    border: 1px solid var(--dark-green) !important;
    background-color: var(--light-green) !important;
    color: var(--primary) !important;

    &:hover {
      background-color: var(--dark-green) !important;
    }
  }

  &__cycle--work {
    border: 1px solid var(--dark-red) !important;
    background-color: var(--light-red) !important;
    color: var(--primary) !important;

    &:hover {
      background-color: var(--dark-red) !important;
    }
  }

  &__actions {
    color: var(--primary);
    position: absolute;
    display: flex;
    gap: 50px;
    bottom: 25px;

    @media (orientation: portrait) {
      @media (max-device-width: 600px) {
        bottom: 80px;
      }
    }

    @media (orientation: landscape) {
      @media (max-device-height: 600px) {
        position: absolute;
        transform: translate(-50%);
        left: 7%;
        bottom: 50%;
      }
    }
  }
}
