.DashboardTableRow {
  letter-spacing: 0.35px;
  font-size: 14px;
  background-color: #4a3960;

  &--work,
  &--break {
    > td {
      color: var(--primary) !important;
    }
  }

  &--work {
    background: linear-gradient(
      90deg,
      var(--dark-red) 0%,
      var(--dark-red) var(--percentage),
      var(--light-red) var(--percentage)
    );
  }

  &--break {
    background: linear-gradient(
      90deg,
      var(--dark-green) 0%,
      var(--dark-green) var(--percentage),
      var(--light-green) var(--percentage)
    );
  }

  > td {
    &:first-child {
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }

    &:last-child {
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }

  &__name {
    display: flex;
    align-items: center;
  }

  &__picture {
    margin-right: 10px !important;
    margin-left: 0 !important;
  }

  &__pomodoros {
    font-weight: bold;
    font-size: 12px;
  }

  &__description {
    font-weight: 500;
    display: block;
    font-size: 11px;
  }

  &__duration {
    font-family: Inconsolata;
    color: var(--primary);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.02em;
  }
}
