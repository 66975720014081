.SignIn {
  width: 100%;
  max-width: 620px;
  margin: 0 0 50px;

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      text-decoration: underline;
    }

    span,
    a {
      color: var(--secondary);
      font-size: 12px;
    }
  }

  &__social-login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
  }

  &__google-button {
    background: #f07068;
    color: #fcfaf0;
    padding: 16px 100px;

    &:hover {
      background: darken(#f07068, 5%);
    }
  }

  &__microsoft-button {
    background: rgb(0, 103, 184);
    color: #fcfaf0;
    padding: 16px 100px;

    &:hover {
      background: darken(rgb(0, 103, 184), 5%);
    }
  }
}
