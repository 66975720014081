* {
  box-sizing: border-box;
}

@font-face {
  font-family: Inconsolata;
  font-weight: normal;
  src: url("../fonts/Inconsolata-Regular.ttf");
}

@font-face {
  font-family: Inconsolata;
  font-weight: 700;
  src: url("../fonts/Inconsolata-Bold.ttf");
}

@font-face {
  font-family: Hansen Grotesque;
  font-weight: normal;
  src: url("../fonts/HansenGrotesque.woff");
}

@font-face {
  font-family: Hansen Grotesque;
  font-weight: 700;
  src: url("../fonts/HansenGrotesque-Bold.woff");
}

@font-face {
  font-family: Hansen Grotesque;
  font-weight: 900;
  src: url("../fonts/HansenGrotesque-Black.woff");
}

:root {
  --primary: #36244d;
  --secondary: #dcbcfd;
  --secondary-rgb: 220, 188, 253;
  --light-red: #fcf4f0;
  --light-green: #e0f9f0;
  --light-yellow: #fcfaf0;
  --dark-red: #f2d7ca;
  --dark-green: #cbebdb;
}

html,
body,
#app {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background: var(--light-yellow);
  font-family: Hansen Grotesque;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
}

.mb-10 {
  margin-bottom: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.font-weight-bold {
  font-weight: bold;
}

.float-right {
  float: right;
}
